import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,FormDataContext,
  BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";
import ResponseModal from "../../components/ResponseModal";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Sell Data Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
   purchaseData();
      };

      const planTypes = [
        "SME Data",
        "Normal Data",
        "Corporate Data"
      ];
   
  useEffect(()=>{
    if(!tempData.dataPlans){
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "dataPlans", 
        value: businessData.data_plans.filter(dataPlan => dataPlan.status*1=== 0)
      }});
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "dataPlan", 
        value: businessData.data_plans.filter(dataPlan => dataPlan.status*1=== 0)[0].id
      }});
     }

    if(!formData.network){
      formDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'network', value: 'MTN' },
      });
      filterNetwork({target:{value:'MTN'}});
    }
  },[])
    
  const filterNetwork =(e)=>{
    var dataPlans=businessData.data_plans;
    dataPlans=dataPlans.filter(dataPlan => dataPlan.network=== e.target.value)
    dataPlans=dataPlans.filter(dataPlan => dataPlan.status*1=== 0)
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "dataPlans", 
      value: dataPlans
    }});

    if(dataPlans[0]) {
      formDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'dataPlan', value: dataPlans[0].id },
      });
    }

  }
            const purchaseData = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Selling data...", isLoading: true },
              });  var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
              var urlencoded = new URLSearchParams();
              urlencoded.append("source", String("WEB"));
              urlencoded.append("plan_id", String(formData.dataPlan));
              urlencoded.append("phone_number", String(formData.phone_number));
              urlencoded.append("payment_method", 'ADMIN');
              console.log("plan id"+ formData.dataPlan)
              var requestOptions = {
                method: "POST",
                headers: myHeaders,
               
                body: urlencoded,
              };
              fetch(
                localStorage.getItem("apiURL") + "data_purchase",
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
                  });

                  tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: 'history', value: {history:props.history, url :"/"} },
                });


                  tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: 'clearData', value:true },
                });
                
                  }
                  else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                }
                 else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    tempDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    tempDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                  {/* <ResponseModal/> */}
                <Row className="justify-content-center align-content-center ">
                 <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Sell Data</b></p>
                <hr className="border-primary" />
                </div>
                <Row>
                  <Col md="6" >
                  {tempData.error && <Alert color="danger">{tempData.error}</Alert>}

                <AvForm 
                className="form-horizontal"
                onValidSubmit={handleSubmit}
              >
                  <p
                  className="  text-left mb-0 "
                >
                  <b>Network</b>
                </p>
                 <select className="form-control mb-2" value={formData.network} name="network" onChange={(e)=>{formOnChange(e);filterNetwork(e)} }>
                 <option value="MTN">MTN</option>
                  <option value="GLO">GLO</option>
                  <option value="AIRTEL">AIRTEL</option>
                  <option value="ETISALAT">ETISALAT</option>
                  </select>

                  <p
                  className="  text-left mb-0 mt-2 "
                >
                  <b>Data Plan</b>
                </p>
                  <select className="form-control mb-2" required value={formData.dataPlan} name="dataPlan" onChange={(e)=>{formOnChange(e)} }>
                  {tempData.dataPlans && tempData.dataPlans.map((dataPlan, index) => { return (<option key={dataPlan.id} 
                  value={dataPlan.id}>{dataPlan.name? dataPlan.name: dataPlan.master_name} --- {planTypes[dataPlan.type]} </option>) })}
                  </select>
              
              
<AvField
                  name="phone_number"
                  label="Phone Number"
                   value={formData.phone_number}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter  phone number"
                  type="number"
                  required
                  validate={{
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Enter valid phone number",
                    },
                  }}
                />
     
     <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  Sell Data
                </Button>

                  </AvForm>
                  </Col></Row>
                          </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                         </React.Fragment>
                         )
                        };
                        
                        export default withRouter(Payments);
                        