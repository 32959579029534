import React, { useState, useContext } from "react";
import { Alert, Button, Col, Row, Card ,Collapse, CardBody,CardHeader} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logoBg from '../../images/logo-dark.png'
import logo from '../../images/logo-sm.png'
import wave from '../../images/wave.svg'
import ResponseModal from "../../components/ResponseModal";

import {
    ModalContext,
   TempDataContext,
   BusinessDataContext
 } from "../../contexts/Contexts";

const SignIn = (props) => {
    const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

   document.title='DataNow-Privacy Policy';

    businessData.merchant && props.history.push("/dashboard");
     
            
        return (
            <React.Fragment>
                 <div className="text-center  pt-4">
                 <Link to=''> <img src={logoBg} alt="" height="36" className="float-left mb-3 mr-3 ml-3"/>
                 </Link> 
                </div>
                <h1 className="m-3 text-center text-dark">DataNow Privacy Policy </h1>

              <Row className='justify-content-center'>
                  <Col xs='12' md='8'>
              <p>
              DataNow is committed to managing your Personal Information in line with global industry best practices. You can read our Privacy Policy to understand how we use your information and the steps we take to protect your information. 
              </p>
                
           
              <h4>Information Security and Warranty Disclaimer</h4>
              <p>
              DATANOW WILL ALWAYS ENSURE THAT THE WEBSITE IS AVAILABLE AT ALL TIMES AND BUG FREE. HOWEVER, IT IS USED AT YOUR OWN RISK.
WE PROVIDE ALL MATERIALS “AS IS” WITH NO WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS, INCLUDING ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY, SECURITY, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, DATANOW MAKES NO WARRANTY THAT OUR WEBSITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT OUR WEBSITE WILL REMAIN FREE FROM ANY INTERRUPTION, BUGS, INNACCURACIES, AND ERROR FREE.
YOUR USE OF OUR SERVICES ARE AT YOUR OWN RISK AND YOU ALONE WILL BE RESPONSIBLE FOR ANY DAMAGE THAT RESULTS IN LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN OBTAINED BY YOU FROM OUR WEBSITE OR OUR SERVICES WILL CREATE ANY WARRANTY OR CONDITION NOT EXPRESSLY STATED.
You are responsible for configuring your information technology, computer programmes and platform in order to access our Services. Please ensure you use your virus protection software or application as we cannot guarantee that our Services will be free from viruses or bugs.
You must not attempt to gain unauthorized access to our Services, computers or databases. You must not misuse our Services by introducing trojans, viruses or other materials which are malicious or technologically harmful.
              </p>

              <h4>Limitation of Liability</h4>
              <p>
              YOUR USE OF DATNOW SITE AND SERVICES IS AT YOUR OWN RISK. YOU AGREE TO THE LIMITATION LIABILITY CLAUSE TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW: DATANOW WILL IN NO WAY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL PUNITIVE, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES OR ANY DAMAGES INCLUDING DAMAGES RESULTING FROM REVENUE LOSS, PROFIT LOSS, USE, DATA, GOODWILL , BUSINESS INTERRUPTION OR ANY OTHER INTANGIBLE LOSSES (WHETHER DATANOW HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR NOT) ARISING OUT OF DATANOW’S WEBSITE OR SERVICES (INCLUDING, WITHOUT LIMITATION TO INABILITY TO USE, OR ARISING FROM THE RESULT OF USE OF DATANOW’S WEBSITE OR SERVICES) WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, TORT, CONTRACT, STATUTE OR ANY OTHER LEGAL THEORY.
            SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS ON THE SCOPE AND DURATION OF SUCH WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU IN THEIR ENTIRITIES, BUT WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW
              </p>

                 </Col>
              </Row>
              <br/>
              <br/>
              <br/>
            <Row className="justify-content-center bg-primary text-white pt-3" id="contact">
           
             <Col xs="12">
             <p style={{fontSize:12}} className="text-center text-white">
                 <Link  className="text-white ml-2 mr-2" to='terms'>Terms and Conditions</Link>
             <Link className="text-white ml-2 mr-2" to='privacy'>Privacy Policy</Link>
             </p>
              </Col> 
              <Col xs="12">
                 <p style={{fontSize:16}} className="text-center pb-4">©{new Date().getFullYear()} Systems Technologies,  All rights reserved.</p>
             </Col>
             </Row>
 

            </React.Fragment>
            );
        };
        

 
        export default withRouter(SignIn);
        