import React, { useContext,useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Breadcrumb, BreadcrumbItem, Input, Table } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

import {
     ModalContext,
    TempDataContext,
    BusinessDataContext
  } from "../../contexts/Contexts";
  import { formatCurrency } from "../../helpers/utils";

  
import mtn from '../../images/mtn.png'
import glo from '../../images/glo.png'
import airtel from '../../images/airtel.png'
import etisalat from '../../images/etisalat.png'
import BusinessTransactions from '../Business/BusinessTransactions';


const Dashboard =(props)=>{
    const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
    const { tempData, tempDataDispatch } = useContext(TempDataContext);
    const{loaderDispatch}= useContext(ModalContext);

    document.title = businessData.business? businessData.business.name+'-DataNow' : 'DataNow';

    const fetchAppData =()=> {
  
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
 
    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        
    };
       fetch(
        localStorage.getItem("apiURL")
        .substr(0, localStorage.getItem("apiURL").length - 1),
        requestOptions
        )
        .then((response) => (response = response.text()))
        .then((response) => {
          const data = JSON.parse(response);
          console.log(data);
          if (data.status === "success") {
            loaderDispatch({ type: 'SET_LOADER',data:{text:"",isLoading:false}})
            businessDataDispatch({ type: 'STORE_APP_DATA', businessData:data.data})}
            else if (data.message === "Token Expired"){
              props.history.push('/signout');    
          }
          
        })
        .catch((error) => {
          console.log("error", error);
        });
   }
 
   useEffect(() => {
     if(!tempData.dataLoaded){ 
      tempDataDispatch({
        type: "SET_FORM_DATA", 
        data: { name: 'dataLoaded', value:true },
      }); 
      loaderDispatch({ type: 'SET_LOADER',data:{text:"Loading",isLoading:true}})
     fetchAppData()
    }
   }, []);
   


   return (<React.Fragment>
      {!businessData.data_plans ? 
    (<div></div>)
    :
    (
            <React.Fragment>
            <Container fluid  >
            <Row className="justify-content-center align-content-center ">
             <Col xl="11" ml="11" sm="12"  className="  p-0 m-0">
            <Card className="mini-stat text-dark shadow">
            <CardBody>
                <div>
                 <Row  >   
                <Col md="4" >
                        <Card className="mini-stat  shadow"  >
                            <CardBody >
                              <Row>
                                  <Col xs="3">
                                  <h1><i className="fas fa-wallet text-dark"></i></h1>
                                  </Col>
                                  <Col xs="9">
                                  <h6 className=" text-uppercase mt-2 small">Wallet Balance</h6>
                                  <h6 className="text-dark">₦ {(businessData.merchant.clearance_level*1!==0 &&
                                   businessData.merchant_settings.show_balance_to_staff*1===1) ? 'XXX,XXX,XXX':
                                  formatCurrency(businessData.business.wallet_balance)}
                                          </h6>
                                  </Col>
                                  </Row>  
                            </CardBody>
                        </Card>
                    </Col>
                             </Row>
                          
                            <Card className="mini-stat  shadow"  >
                            <CardBody >
                                  <Row>

                                  <Col lg="4" >
                                <Row>
                                  <Col xs="3" >
                                 <img src={mtn} alt="mtn" height="40" />
                                  </Col>
                                  <Col xs="9" >
                                  <h6  className=" text-uppercase mt-0 mb-0 " style={{fontSize:14}}><label className="small">
                                  {(businessData.admin_settings.MTN_data_master*1 === 0 )? 
                                   <i className="mdi mdi-check-circle text-success" ></i>
                                     :
                                     <i className="mdi mdi-alert-circle text-danger"></i>
                                    }
                                 Data : </label>₦{formatCurrency( businessData.business.MTN_data_airtime)} </h6>
                                  <h6  className=" text-uppercase mt-2 mb-0  " style={{fontSize:14}}><label className="small"> 
                                  {businessData.airtime_plans[businessData.airtime_plans.findIndex(airtimePlan=> airtimePlan.network==='MTN')].master_status*1 === 0? 
                                   <i className="mdi mdi-check-circle text-success" ></i>
                                     :
                                     <i className="mdi mdi-alert-circle text-danger"></i>
                                    }Airtime:</label> ₦{formatCurrency( businessData.business.MTN_vtu_airtime)}</h6>
                                    <h6  className=" text-uppercase mt-2 mb-0 " style={{fontSize:14}}><label className="small">Corporate Data Balance: </label>{formatCurrency( businessData.business.MTN_CORPORATE_data_airtime)}MB</h6>
                                    <h6  className=" text-uppercase mt-2 mb-0 " style={{fontSize:14}}><label className="small">SME Data Balance: </label>{formatCurrency( businessData.business.MTN_data_balance)}MB</h6>
                                    <h6  className=" text-uppercase mt-2 mb-0  " style={{fontSize:14}}><label className="small">SME Data Shares:</label> {formatCurrency( businessData.business.MTN_data_share)}  </h6>
                                   </Col>
                                  </Row>
                    </Col>
                    <Col lg="8">
                    <Row>
                    <Col lg="4"  >
                    <Row>
                    <Col className="d-lg-none" xs="12"><hr /></Col>

                                  <Col xs="3" >
                                 <img src={glo} alt="glo" height="40"   />
                                  </Col>
                                  <Col xs="9" >
                                  <h6  className=" text-uppercase mt-0 mb-0 " style={{fontSize:14}}><label className="small"> {businessData.admin_settings.GLO_data_master === 0? 
                                   <i className="mdi mdi-check-circle text-success" ></i>
                                     :
                                     <i className="mdi mdi-alert-circle text-danger"></i>
                                    }Data : </label>₦{formatCurrency( businessData.business.GLO_data_airtime)} </h6>
                                  <h6  className=" text-uppercase mt-2 mb-0  " style={{fontSize:14}}><label className="small">
                                  {businessData.airtime_plans[businessData.airtime_plans.findIndex(airtimePlan=> airtimePlan.network==='GLO')].master_status*1 === 0? 
                                   <i className="mdi mdi-check-circle text-success" ></i>
                                     :
                                     <i className="mdi mdi-alert-circle text-danger"></i>
                                    }Airtime:</label> ₦{formatCurrency( businessData.business.GLO_vtu_airtime)}</h6>
                                </Col>
                                  </Row>
                    </Col>

                    <Col lg="4" >
                    <Row>
                    <Col className="d-lg-none" xs="12"><hr /></Col>

                                  <Col xs="3" >
                                 <img src={airtel} alt="airtel" height="40"   />
                                  </Col>
                                  <Col xs="9" >
                                  <h6  className=" text-uppercase mt-0 mb-0 " style={{fontSize:14}}><label className="small">{businessData.admin_settings.AIRTEL_data_master === 0? 
                                   <i className="mdi mdi-check-circle text-success" ></i>
                                     :
                                     <i className="mdi mdi-alert-circle text-danger"></i>
                                    }Data : </label>₦{formatCurrency( businessData.business.AIRTEL_data_airtime)} </h6>
                                  <h6  className=" text-uppercase mt-2 mb-0  " style={{fontSize:14}}><label className="small">
                                  {businessData.airtime_plans[businessData.airtime_plans.findIndex(airtimePlan=> airtimePlan.network==='AIRTEL')].master_status*1 === 0? 
                                   <i className="mdi mdi-check-circle text-success" ></i>
                                     :
                                     <i className="mdi mdi-alert-circle text-danger"></i>
                                    }Airtime:</label> ₦{formatCurrency( businessData.business.AIRTEL_vtu_airtime)}</h6>
                                </Col>
                                  </Row>
                    </Col>

                    <Col lg="4">
                    <Row>
                        <Col className="d-lg-none" xs="12"><hr /></Col>
                        
                                  <Col xs="3" >
                                 <img src={etisalat} alt="etisalat" height="40"   />
                                  </Col>
                                  <Col xs="9" >
                                  <h6  className=" text-uppercase mt-0 mb-0 " style={{fontSize:14}}><label className="small">{businessData.admin_settings.ETISALAT_data_master === 0? 
                                   <i className="mdi mdi-check-circle text-success" ></i>
                                     :
                                     <i className="mdi mdi-alert-circle text-danger"></i>
                                    }Data : </label>₦{formatCurrency( businessData.business.ETISALAT_data_airtime)} </h6>
                                  <h6  className=" text-uppercase mt-2 mb-0  " style={{fontSize:14}}><label className="small">
                                  {businessData.airtime_plans[businessData.airtime_plans.findIndex(airtimePlan=> airtimePlan.network==='ETISALAT')].master_status*1 === 0? 
                                   <i className="mdi mdi-check-circle text-success" ></i>
                                     :
                                     <i className="mdi mdi-alert-circle text-danger"></i>
                                    }Airtime:</label> ₦{formatCurrency( businessData.business.ETISALAT_vtu_airtime)}</h6>
                                </Col>
                                  </Row>
                    </Col>
                    </Row>
                    </Col>
                </Row>
                </CardBody>
                        </Card>
         </div>
         <div>
         <div className="justify-content-center text-center">
             
        <Link to="sell-data">
          <button className="btn btn-primary m-2" >
                                   <i className="ti-signal"></i>  Sell Data
                                     </button>
                                     </Link> 
        
                                     <Link to="sell-airtime">
      <button className="btn btn-warning m-2" >
                                   <i className="ti-mobile"></i>  Sell Airtime
       </button>
       </Link>

       
       <Link to="marketplace">
      <button className="btn btn-danger m-2" >
      <i className=" ti-shopping-cart "></i> Marketplace
       </button>
       </Link>

       <Link to="transfer-to-customer">
      <button className="btn btn-success m-2" >
      <i className="ti-user "></i>  Transfer to Customer Wallet
       </button>
       </Link>
       </div>
       <hr></hr>
        
       <Row>
                   <Col xl="12" md="12" >
                     <Card className="mini-stat  shadow text-dark border border-primary mt-0 mb-2">
                     <p className="mt-2 mb-2 pl-2"><i className="mdi mdi-alert-circle-outline  text-danger mdi "></i>
                    You can top up your wallet with Auto Agent by making a transfer from your mobile/internet banking app into our verified account.</p>
                    <p className="mt-2 mb-2 pl-2">
                    Enter your REFERENCE CODE <b>X2X{businessData.business.reference_code}X2X2</b> in the Remark/Description/Narration part of the transfer form </p>
                    <p className="mt-2 mb-2 pl-2">
                    <b>Account Number: 1481853002 (Access Bank), Account Name: SYSTEMS TECHNOLOGIES LIMITED </b></p>
                    </Card>
                    </Col>
                    </Row>

          <h4>Recent Payments</h4>
                                                   
              <BusinessTransactions  summary={true}/>
 
         </div>
     
         </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          </React.Fragment>
         )
}
</React.Fragment>
)
}

export default withRouter((Dashboard));