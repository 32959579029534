import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Topbar from './Topbar';
import Footer from './Footer';

import { TempDataContext ,FormDataContext} from '../../contexts/Contexts';

// render if Auth Layout
const Layout = (props) => {
  const {tempData, tempDataDispatch}= useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

 

const unlisten = props.history.listen((location, action) => {
 if(tempData.currentPage!== window.location.pathname   ){
 tempDataDispatch({
  type: "CLEAR_FORM_DATA",
}); 

tempDataDispatch({
  type: "SET_FORM_DATA", 
  data: { name: 'currentPage', value:window.location.pathname },
});

  if( window.location.pathname!=='/customer-transactions'){
  formDataDispatch({
    type: "CLEAR_FORM_DATA",
  });
  }

}

window.scrollTo(0,0);

console.log("on route change"); 
  });

 
  document.body.classList.remove('bg-primary');
  return <React.Fragment>
      <Topbar />
    <div className="wrapper">
      <div className="content-page">
        <div className="content">
          {props.children}
        </div>
      </div>
    </div>
    <Footer />
  </React.Fragment>
}
  
 
 

export default withRouter((Layout));
