
export const businessDataReducer = (state, action) => {
  switch (action.type) {
       case 'STORE_APP_DATA':{
      return  {...state,...action.businessData} ;
    }
    
    case 'SIGNOUT':{
      return [] ;
    }
    default:
      return state;
  }
} 