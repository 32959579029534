import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import BusinessDataContextProvider from './contexts/BusinessDataContext';
import ModalContextProvider from './contexts/ModalContext';
import TempDataContextProvider from './contexts/TempDataContext';
import Loader from './components/Loader';
import ResponseModal from './components/ResponseModal';
import FormDataContextProvider from './contexts/FormDataContext';

const app = (
    <TempDataContextProvider>
   <BusinessDataContextProvider>
   <ModalContextProvider>
   <FormDataContextProvider>
     <Loader/>
    <ResponseModal/>
        <BrowserRouter>
            <App />
        </BrowserRouter>
         </FormDataContextProvider>
        </ModalContextProvider>
        </BusinessDataContextProvider>
        </TempDataContextProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
