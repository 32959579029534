import React, { createContext, useReducer, useEffect } from 'react';
import { businessDataReducer } from '../reducers/BusinessDataReducer';

export const BusinessDataContext = createContext();

const BusinessDataContextProvider = (props) => {
  const [businessData, businessDataDispatch] = useReducer(businessDataReducer, [], () => {
    const localData = localStorage.getItem('businessData');
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem('businessData', JSON.stringify(businessData));
  }, [businessData]);
  
  return (
    <BusinessDataContext.Provider value={{ businessData: businessData, businessDataDispatch }}>
      {props.children}
    </BusinessDataContext.Provider>
  );
}
 
export default BusinessDataContextProvider;