import React, { useState, useContext } from "react";
import { Alert, Button, Col, Row, Card ,Collapse, CardBody,CardHeader} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logoBg from '../../images/logo-dark.png'
import logo from '../../images/logo-sm.png'
import wave from '../../images/wave.svg'
import ResponseModal from "../../components/ResponseModal";

import {
    ModalContext,
   TempDataContext,
   BusinessDataContext
 } from "../../contexts/Contexts";

const SignIn = (props) => {
    const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

   document.title='DataNow-Terms and Agreement';

    businessData.merchant && props.history.push("/dashboard");
     
            
        return (
            <React.Fragment>
                 <div className="text-center  pt-4">
                 <Link to=''> <img src={logoBg} alt="" height="36" className="float-left mb-3 mr-3 ml-3"/>
                 </Link> 
                </div>
                <h1 className="m-3 text-center text-dark">DataNow Terms and Conditions </h1>

              <Row className='justify-content-center'>
                  <Col xs='12' md='8'>
                  <h4>Introduction</h4>
              <p>This Website Terms and Conditions (“Terms”) contained herein on this webpage is a legal agreement between you, as a prospective merchant of DataNow and shall govern your access to and use of DataNow services which include all pages within DataNow website, mobile applications and other products and services (collectively referred to as the “Services”).
                By signing up for an account on DataNow via our websites and/or services, you are deemed a merchant and agree to these DataNow Terms and Conditions (“Terms”). You must not use any of the Services, if you have any objection to any of these Terms.
                </p>
                
                <h4>Age Restriction</h4>
              <p>Our website and services are not directed to children under 18. You are allowed to use the Services if you are an adult. We do not knowingly transact or provide any service to children under 18.  </p>
            


                <h4>Representation and Warranties</h4>
              <p>
              You represent and warrant to DataNow that:  
                </p>
                <ul>
                <li>You have full power and authority to enter into, execute, deliver and perform this Agreement for your business/company;</li>
                <li>you are duly organised, authorised and in good standing under the laws of the Federal Republic of Nigeria or any state, region or country of your organisation and are duly authorised to do business in all other states, regions or countries in which your business operates.</li>
                </ul>

              <h4>Intellectual Property</h4>
              <p>
              We do not grant any right or license to any DataNow intellectual property rights by implication, estoppel or otherwise other than those expressly mentioned in this Agreement.
              </p>

              <h4>Data Compliance</h4>
              <p>
              You agree to comply with all data privacy and security requirements of the Payment Card Industry Data Security Standard (PCI DSS Requirements”) and under any applicable law or regulation that may be in force, enacted or adopted regarding confidentiality, your access, use, storage and disclosure of user information. Information on the PCI DSS can be found on the PCI Council’s website. It is your responsibility to comply with these standards.
Our payment gateway is responsible for the security and protection of Card Holder Data (CHD) processed through the Services.
We acknowledge that you own all your customers’ data. You hereby grant DataNow a perpetual, irrevocable, sub-licensable, assignable, worldwide, royalty-free license to use, reproduce, electronically distribute, and display your customers’ data for the following purposes: 

              </p>
                <ol>
                <li>Providing and improving our services</li>
                <li>Internal usage, including but not limited to, data analytics and metrics so long as individual customer data has been anonymized and aggregated with other customer data; </li>
                <li>Complying with applicable legal requirements and assisting law enforcement agencies by responding to requests for the disclosure of information in accordance with local laws; and </li>
                <li>Any other purpose for which consent has been provided by your customer.</li>
                </ol>

                <h4>License to Use Our Services</h4>
              <p>
              We grant you a non-assignable, non-exclusive and revocable license to use the Services permitted by these terms. This license grant includes all updates, upgrades, new versions and replacement software for you to use in connection with our services. If you do not comply with the documentation and any other requirements provided by DataNow, then you will be liable for all resulting damages suffered by you, DataNow and third parties. Unless otherwise provided by applicable law, you agree not to alter, re-design, reproduce, adapt, display, distribute, translate, disassemble, reverse engineer, or otherwise attempt to create any source code that is derived from the software. Upon expiration or termination of this Agreement, you will immediately cease all use of any Software.
               </p>

               <h4>Publicity</h4>
              <p>
              You hereby grant DataNow permissions to use your name and logo in our marketing materials including, but not limited to use on our website, in customer listings, in interviews and in press releases. Such publicity does not imply an endorsement for your products and services.                </p>

               <h4>Know Your Customer</h4>
              <p>
              You agree that, you are solely responsible for verifying the identities of your customers using the Services via API, ensuring that they are authorised to carry out the transactions on your platform, and determining their eligibility to purchase your products and services. 
You are also required to maintain information and proof of service or product delivery to your customer. Where a dispute occurs needing resolution, you may be required to provide DataNow with these.                </p>

               <h4>Our Fees and Pricing Schedule</h4>
              <p>
              You agree to pay us for the services we render as a payment gateway and automation provider for your goods and services. Our Fees will be calculated as demonstrated on the Pricing page on the website. The Fees on our Pricing page is integral to and forms part of this Agreement. We reserve the right to revise our Fees.
In the event that we revise our fees we will notify you within 3 days of such change               </p>

               <h4>Payouts</h4>
              <p>
              Subject to the terms of this Agreement, DataNow will send to your designated bank account all amounts settled and due to you from your transactions, minus our fees as stated in the Fee Schedule, any Reversals, Invalidated Payments, Chargebacks, Refunds or other amounts that you owe to DataNow under this Agreement (“Payout”). If the Payout is not sufficient to cover the amounts due, you agree that we may debit your Bank Account for the applicable amounts, and/or set-off the applicable amounts against future Payouts. Upon our request, you agree to provide us with all necessary bank account and related information and grant us permission to debit amounts due from your Bank Account.
               </p>

               <h4>Indemnification</h4>
              <p>
              You hereby indemnify DataNow and undertake to keep DataNow, its staff and affiliates indemnified against any losses, damages, costs, liabilities and expenses (including without limitation reasonable legal fees and expenses) arising out of any breach by you of any provision of these Terms, or arising out of any claim that you have breached any provision of these Terms. You will indemnify and hold DataNow harmless from and against any claim, suit or proceedings brought against DataNow arising from or in connection with violations of Intellectual Property or other rights of third parties in relation to your use of the Services.             
                </p>

                <h4>Breaches of these Terms</h4>
              <p>
              Without prejudice to DataNow’s other rights under these Terms, if you breach these Terms in any way, DataNow may take such action as DataNow deems appropriate to deal with the breach, including suspending your access to your account, debiting data, airtime and Naira wallets, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.             
                </p>

                <h4>Notification of Errors</h4>
              <p>
              You agree to notify us immediately any error is detected while reconciling transactions that have occurred using the Services. We will investigate and rectify the errors where verified. In the event that we notice any errors, we will also investigate and rectify such errors. 
Where we owe you money as a result of such errors, we will refund the amounts owed to you by a bank transfer to your Bank Account or airtime or data wallet. 
If a transaction is erroneously processed through your platform, report to us immediately. We will investigate any such reports and attempt to rectify the errors by crediting or debiting your data, airtime or Naira wallets as appropriate. 
Failure to notify us within 5 (five) days of the occurrence of an error will be deemed a waiver of your rights to amounts that are owed to you due to an error.
                </p>
            
                <h4>Security and Fraud Controls</h4>
              <p>
              You agree to use measures that are appropriate for your business to reduce the risk of fraud. 
In the event that you suspect any fraudulent activity by a customer, you agree to notify DataNow immediately and quit the delivery of the service. In addition, where we suspect that there have been frequent fraudulent transactions on your account, we reserve the right to cancel our service to you and/or your account
                </p>

                <h4>Termination</h4>
              <p>
              You may terminate this Agreement by closing your DataNow Account.
We may suspend your DataNow Account and your access to DataNow services and any funds, or terminate this Agreement, if;
                </p>
                <ol>
                <li>You do not comply with any of the provisions of this Agreement;</li>
                <li>We are required to do so by a Law; </li>
                <li>We are directed by a Card Network or issuing financial institution; or</li>
                <li>Where a suspicious or fraudulent transaction occurs</li>
                </ol>


                <h4>Privacy Policy</h4>
              <p>
              DataNow is committed to managing your Personal Information in line with global industry best practices. You should read our Privacy Policy to understand how we use your information and the steps we take to protect your information.                
              </p>

              <h4>Governing Law</h4>
              <p>
              These Terms shall be interpreted and governed in accordance with the Laws of the Federal Republic of Nigeria and you submit to the non-exclusive jurisdiction of the Courts located in Nigeria for the resolution of any dispute
              </p>

              <h4>Information Security and Warranty Disclaimer</h4>
              <p>
              DATANOW WILL ALWAYS ENSURE THAT THE WEBSITE IS AVAILABLE AT ALL TIMES AND BUG FREE. HOWEVER, IT IS USED AT YOUR OWN RISK.
WE PROVIDE ALL MATERIALS “AS IS” WITH NO WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS, INCLUDING ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY, SECURITY, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, DATANOW MAKES NO WARRANTY THAT OUR WEBSITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT OUR WEBSITE WILL REMAIN FREE FROM ANY INTERRUPTION, BUGS, INNACCURACIES, AND ERROR FREE.
YOUR USE OF OUR SERVICES ARE AT YOUR OWN RISK AND YOU ALONE WILL BE RESPONSIBLE FOR ANY DAMAGE THAT RESULTS IN LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN OBTAINED BY YOU FROM OUR WEBSITE OR OUR SERVICES WILL CREATE ANY WARRANTY OR CONDITION NOT EXPRESSLY STATED.
You are responsible for configuring your information technology, computer programmes and platform in order to access our Services. Please ensure you use your virus protection software or application as we cannot guarantee that our Services will be free from viruses or bugs.
You must not attempt to gain unauthorized access to our Services, computers or databases. You must not misuse our Services by introducing trojans, viruses or other materials which are malicious or technologically harmful.
              </p>

              <h4>Limitation of Liability</h4>
              <p>
              YOUR USE OF DATNOW SITE AND SERVICES IS AT YOUR OWN RISK. YOU AGREE TO THE LIMITATION LIABILITY CLAUSE TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW: DATANOW WILL IN NO WAY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL PUNITIVE, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES OR ANY DAMAGES INCLUDING DAMAGES RESULTING FROM REVENUE LOSS, PROFIT LOSS, USE, DATA, GOODWILL , BUSINESS INTERRUPTION OR ANY OTHER INTANGIBLE LOSSES (WHETHER DATANOW HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR NOT) ARISING OUT OF DATANOW’S WEBSITE OR SERVICES (INCLUDING, WITHOUT LIMITATION TO INABILITY TO USE, OR ARISING FROM THE RESULT OF USE OF DATANOW’S WEBSITE OR SERVICES) WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, TORT, CONTRACT, STATUTE OR ANY OTHER LEGAL THEORY.
            SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS ON THE SCOPE AND DURATION OF SUCH WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU IN THEIR ENTIRITIES, BUT WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW
              </p>

              <h4>Updates, Modifications and Amendments</h4>
              <p>
              We may need to update, modify or amend our Terms and Conditions as our technology evolves. We reserve the right to make changes to this Terms and Conditions at any time by giving notice to users on this page.
            We advise that you check this page often, referring to the date of the last modification on the page If you have any objection to any of the changes to this Terms and Conditions, you must cease using our website and/or services immediately.
              </p>

              <h4>Miscellaneous</h4>
              <p>
              You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. Assigning or sub-contracting any of your rights or obligations under these Terms of Use to any third party is prohibited. We reserve the right to transfer, assign or sub-contract the benefit of the whole or part of any rights or obligations under these Terms of Use to any third party.
               </p>

               <h4>Acceptable Use Policy</h4>
              <p>
              By accessing or using DataNow, you agree to comply with the terms and conditions of this Acceptable Use Policy.               
              </p>

              <h4>Restricted Activities</h4>
              <p>
              You may not use DataNow in connection with any product, service, transaction or activity that:
              </p>

              <ol>
                <li>Violates any law or government regulation, or promotes or facilitates such by third parties;</li>
                <li>Is fraudulent, deceptive, unfair or predatory;</li>
                <li>Causes or threatens reputational damage to us or any Card Network;</li>
                </ol>

                </Col>
              </Row>
              <br/>
              <br/>
              <br/>
            <Row className="justify-content-center bg-primary text-white pt-3" id="contact">
           
             <Col xs="12">
             <p style={{fontSize:12}} className="text-center text-white">
                 <Link  className="text-white ml-2 mr-2" to='terms'>Terms and Conditions</Link>
             <Link className="text-white ml-2 mr-2" to='privacy'>Privacy Policy</Link>
             </p>
              </Col> 
              <Col xs="12">
                 <p style={{fontSize:16}} className="text-center pb-4">©{new Date().getFullYear()} Systems Technologies,  All rights reserved.</p>
             </Col>
             </Row>
 

            </React.Fragment>
            );
        };
        

 

        
       

        export default withRouter(SignIn);
        