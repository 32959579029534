import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { Modal,ModalBody} from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

  document.title = "FAQ";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (dataPlan) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'id', value: dataPlan.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'question', value: dataPlan.question },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'answer', value: dataPlan.answer },});
     tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });  
  }

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("question", String(formData.question?formData.question:''));
    urlencoded.append("answer", String(formData.answer?formData.answer:''));
   
    var requestOptions = {
      method: formData.id ?  "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
      formData.id ?  localStorage.getItem("apiURL") + "faq/"+formData.id  : localStorage.getItem("apiURL") + "faq"  ,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
   
       formData.id ?  tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "dataPlans", value:tempData.dataPlans.map((dataPlan) => {
            if(data.data.id===dataPlan.id) return {...dataPlan, ...data.data}
            return dataPlan;
           } 
         )}
          }) : 
          fetchDataPlans();

        }
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.dataPlansLoaded ) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'dataPlansLoaded', value: true },
      });
    }
  }, []);
     

    
  const DataPlan = (props) => {

    return (
      <React.Fragment >
       <tr>
             <td >{props.dataPlan.question}</td>
             <td >{props.dataPlan.answer}</td>
             <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.dataPlan)}} >Edit</td>
             <td className="text-danger" style={{cursor:"pointer"}} onClick={()=>{deleteFAQ(props.dataPlan.id)}} >Delete</td>
            </tr>
              </React.Fragment>
              );
            };
        

            const fetchDataPlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving FAQ...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
              };
              fetch(
                localStorage.getItem("apiURL") + "faq"  ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                     tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "dataPlans", value: data.data },
                    });
                   
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
          
              
            const deleteFAQ = (id) => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Deleting FAQ...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "DELETE",
                headers: myHeaders,
              };
              fetch(
                localStorage.getItem("apiURL") + "faq/" +id,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
                  });
                  
                  tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: "dataPlans", value:tempData.dataPlans.filter(dataPlan => 
                     dataPlan.id!==id 
                  )},
                    });
         
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
          
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
          
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>FAQs</b>
                <b className="text-primary float-right" style={{cursor:'pointer'}} onClick={()=>{
                  planSelect ({id:false})
                }}><i className="mdi mdi-plus"></i> Add FAQ</b></p>
                <hr className="border-primary" />
                </div>
                      {tempData.dataPlans && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing  {tempData.dataPlans.length} FAQ</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                      
                      <th data-priority="1">Question</th>
                      <th data-priority="1">Answer</th>
                      <th data-priority="1"></th>
                      <th data-priority="1"></th>

                      </tr>
                      </thead>
                      <tbody>
                      { tempData.dataPlans.map((dataPlan) => {
                        return <DataPlan key={dataPlan.id} dataPlan={dataPlan} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.isModalOpen && ( <Modal isOpen={tempData.isModalOpen}  >
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {  tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "isModalOpen", value:false },
            });}} >
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="text-uppercase text-left mb-0 " ><b>{formData.id ? 'Edit FAQ': 'Add FAQ'}</b></p>
                 
                  <hr className="border-primary" />
                
                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >
 
                  <AvField
                  name="question"
                  label="Question"
                  value={formData.question}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Question"
                  type="text"
                 />

                 
                 <textarea
                      onChange={formOnChange}
                      value={formData.answer}
                      name='answer'
                      placeholder="answer"
                      style={{width:'100%',height:'200px',fontFamily:'monospace'}}
                      />
     
                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  {formData.id ? 'Save Changes': 'Add FAQ'}
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        