import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
  TempDataContext,
  BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
   const [modalOpen, setModal ] = useState(false)
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

  document.title = "App Settings-DataNow";
   
  
  useEffect(() => {
    if(!tempData.subscriptionLoaded ) {
      fetchTransactions(1);
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'subscriptionLoaded', value: true },
      });
    }
  }, []);
    
  
  
  const Setting = (props) => {
      const [value,setValue]= useState(props.setting.value)

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving setting...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("value", String(value));
    urlencoded.append("id", String(props.setting.id));
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
     
      body: urlencoded,
    };
    fetch(
      localStorage.getItem("apiURL") + "business_settings" ,
      requestOptions
      )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "settings", value:tempData.settings.map((setting) => {
             if(setting.id===props.setting.id)setting.value=data.data.value;
             return setting;
            } 
          )},
            });
 
        } 
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      })
      .catch((error) => {
        console.log("error", error);
        tempDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });

  };

    return (
        <Col xl="6" md="12">
        <AvForm
      className="form-horizontal m-t-30"
      onValidSubmit={handleSubmit}>
           <Card className="mini-stat shadow text-dark">
                                        <CardBody>
                        <Row >
      <Col xl="8" md="8" xs="9">
     { props.setting.type*1=== 1 ? (
      <AvField
      label={props.setting.setting}
      name={props.setting.setting}
      value={value}
      onChange={(e) => { setValue(e.target.value) }}
       type="text"
       />
      )
      :
      (
        <React.Fragment>
        <p
        className="  text-left mb-0  "
      >
        <b>{props.setting.setting}</b>
      </p>
        <select required className="form-control mb-2" value={value} name={props.setting.setting}  onChange={(e) => { setValue(e.target.value) }}>
         <option value="0">Enabled</option>
          <option value="1">Disabled</option>
          </select>
          </React.Fragment>
      )
  }
    </Col>  
    <Col  xl="4" md="4" xs="3">

      <Button
      className="btn-primary  waves-effect waves-light mt-4"
      type="submit"
      >
      Save
      </Button>
      </Col>

      </Row>
      </CardBody>
      </Card>
      </AvForm>
      </Col>
         );
            };
            
            const fetchTransactions = (page) => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving settings...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
              
              var requestOptions = {
                method: "GET",
                headers: myHeaders,
               
              };
              fetch(
                localStorage.getItem("apiURL") + "business_settings" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    const objectValues = Object.values(data.data.settings);
                    console.log(objectValues);
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "settings", value: objectValues },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "current_page", value: data.data.current_page },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "last_page", value: data.data.last_page },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "per_page", value: data.data.per_page },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "total", value: data.data.total },
                    });
                  }
                  else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                }
                 else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    tempDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    tempDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  tempDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>App Settings</b></p>
                <hr className="border-primary" />
                </div>
                <Row>
                {tempData.settings && tempData.settings.map((setting) => {
                        return <Setting key={setting.id} setting={setting} />;
                      })}
                      </Row>

                          </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        