import React, { useState, useContext } from "react";
import { Alert, Button, Col, Row, Card ,Collapse, CardBody,CardHeader} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logoBg from '../../images/logo-dark.png'
import logo from '../../images/logo-sm.png'
import wave from '../../images/wave.svg'
import ResponseModal from "../../components/ResponseModal";
import { formatCurrency } from "../../helpers/utils";

import {
    ModalContext,
   TempDataContext,
   BusinessDataContext
 } from "../../contexts/Contexts";

const SignIn = (props) => {

    const { loaderDispatch } = useContext(ModalContext);
    const {   tempData, tempDataDispatch } = useContext(TempDataContext);
    const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

    document.title='DataNow';

    businessData.merchant && props.history.push("/dashboard");
    
    const price=250000;

    const formOnChange = (e) => {
        tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: e.target.name, value: e.target.value },
        });
    };
    const handleSubmit = (e) => {
        e.persist();
        e.preventDefault();
         loaderDispatch({
            type: "SET_LOADER",
            data: { text: "signing in...", isLoading: true },
        });
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        var urlencoded = new URLSearchParams();
        urlencoded.append("identifier", String(tempData.identifier));
        urlencoded.append("password", String(tempData.password));
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
           
        };
        fetch(
            localStorage.getItem('apiURL')+"signin",
            requestOptions
            )
            .then((response) => (response = response.text()))
            .then((response) => {
                const data = JSON.parse(response);
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
                if (data.status === "success") {
                    console.log(data)
                tempDataDispatch({
                    type: "SET_FORM_DATA", 
                    data: { name: 'dataLoaded', value:true },
                  }); 
                   businessDataDispatch({ type: 'STORE_APP_DATA', businessData:{...data.data,token:data.token}})
               
                } else {
                    tempDataDispatch({
                        type: "SET_ERROR",
                        data: data.message, 
                    });
                }
            })
            .catch((error) => {
                console.log("error", error);
                tempDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                });
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
            });
        };
        
        const submitContact = (e) => {
            e.persist();
            e.preventDefault();
            loaderDispatch({
                type: "SET_LOADER",
                data: { text: "sending message...", isLoading: true },
            });
            
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("Accept", "application/json");
            var urlencoded = new URLSearchParams();
            tempData.contactName && urlencoded.append("name", String(tempData.contactName));
            tempData.contactPhone && urlencoded.append("phone_number", String(tempData.contactPhone));
            tempData.contactEmail && urlencoded.append("email", String(tempData.contactEmail));
            tempData.contactMessage && urlencoded.append("email", String(tempData.contactMessage));
            urlencoded.append("password", String(tempData.password));
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
               
            };
            fetch(
                localStorage.getItem('apiURL')+"contact",
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                    const data = JSON.parse(response);
                    loaderDispatch({
                        type: "SET_LOADER",
                        data: { text: "", isLoading: false },
                    });
                    if (data.status === "success") {
                        tempDataDispatch({
                            type: "SET_FORM_DATA",
                            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
                        });
                    } else {
                        tempDataDispatch({
                            type: "SET_ERROR",
                            data: data.message,
                        });
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    tempDataDispatch({
                        type: "SET_ERROR",
                        data: "unable to connect to server",
                    });
                    loaderDispatch({
                        type: "SET_LOADER",
                        data: { text: "", isLoading: false },
                    });
                });
            };
            
        return (
            <React.Fragment>
                <div  style={{position:'relative'}}>
                <img className=" mt-0" src={wave} style={{width:'100%',bottom:'0',position:'absolute'}} alt=""/>

                <div className="text-center  pt-4">
                   <img src={logoBg} alt="" height="36" className="float-left mb-3 mr-3 ml-3"/>
                <Link to="" onClick={()=>{document.getElementById('pricing').scrollIntoView({behavior: "smooth", block: "start"}) }}
                 className="text-primary m-3"><b>PRICING</b></Link>
                <Link to="" onClick={()=>{document.getElementById('solutions').scrollIntoView({behavior: "smooth", block: "start"}) }} className="text-primary m-3"><b>SOLUTIONS</b></Link>
                <Link to=""  onClick={()=>{document.getElementById('faq').scrollIntoView({behavior: "smooth", block: "start"}) }} className="text-primary m-3"><b>FAQ</b></Link>
                <Link to=""  onClick={()=>{document.getElementById('contact').scrollIntoView({behavior: "smooth", block: "start"}) }} className="text-primary m-3"><b>CONTACT</b></Link>
                   
                    </div>
                <Row className="mt-5 ">
                    <Col lg="7"  >
                        <Row    >
                            <Col lg="2" ></Col>
                             <Col lg="8"  ><h1 className="m-3 text-center text-dark">Expand your business with our 24/7 Automated Solutions </h1>
                             <h5 className="m-3">DataNow offer businesses premium services to boost their Data, Airtime, Cable TV and other digital sales.
                              Business with us will be a pleasing experience burned into your memory.</h5>
                              <Link   to="signup"><button className="btn m-3 text-white" style={{backgroundColor:' #f57f17'}}>Get Started</button>
                              </Link>
                              </Col>
                            </Row>
                            </Col>
              <Col lg="5" className=" mb-0 ">
                  <div className="wrapper-page">
            <Card className="overflow-hidden account-card mx-3 shadow ">
            <div className=" p-4 text-white text-center position-relative bg-primary">
        <h4 className="font-20 m-b-5 mb-4">Sign In</h4>
            <Link to="/" className="logo logo-admin">
            <img src={logo} height="36" alt="logo" />
            </Link>
            </div>
            <div className="account-card-content">
            {tempData.error && <Alert color="danger">{tempData.error}</Alert>}
            <AvForm
            className="form-horizontal m-t-30"
            onValidSubmit={handleSubmit}
            >
            <AvField
            name="identifier"
            label="Email or Phone Number"
            value={tempData.identifier}
            onChange={(e) => {
                formOnChange(e);
            }}
            placeholder="Email or Phone Number"
            type="text"
            required
            />
            <AvField
            name="password"
            label="Password"
            value={tempData.password}
            onChange={(e) => {
                formOnChange(e);
            }}
            placeholder="Enter Password"
            type="password"
            required
            />
            
            <Row className="form-group m-t-20">
            <Col sm="6"></Col>
            <Col sm="6" className="text-right">
            <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="submit"
            >
            Sign In
            </Button>
            </Col>
            </Row>
            
            <Row className="form-group m-t-10 mb-0">
            <Col md="12" className="m-t-20">
            <Link to="/forgotPassword" >
            <i className="mdi mdi-lock"></i> Forgot your password?
            </Link>
            </Col>
            </Row>
            </AvForm>
            </div>
            </Card>
            <div className="m-t-40 text-center">
            <p className="mb-5">
            Don't have an account? 
            <Link to="/signup" className="font-500 text-primary"          
> Signup now
            </Link>
            </p>
            </div>
            </div>
            </Col>
            </Row>
            </div>
            <div  style={{backgroundColor:'#4E5CCF'}} className="pb-5"  id="solutions">
            <h2 className="p-3 text-center text-white m-0"   > Our Solutions</h2> 
            <Row className="justify-content-center">
                <Col md="9" className=" align-self-center  ">
            <Row className="text-white ">
            <Solution icon="mdi mdi-ship-wheel mdi-spin " title="Automation" text="We offer speed blazing automation services for data, airtime, cable TV subscription 
            , electricity bills payments"/>
            <Solution icon="mdi mdi-apps " title="Customers App" text="DataNow will help you expand your business with Android, iOS and web application packages that comes with it."/>
            <Solution icon="mdi mdi-clock-fast" title="Instant App Deployment" text="Customize and Deploy your customers' applications within 5 minutes"/>
            <Solution icon="mdi mdi-code-braces " title="API" text="Have an existing system? Connect to our services using our REST APIs"/>
            <Solution icon="mdi mdi-cash-refund" title="Airtime to Cash" text="Cash out more! Airtime to Cash feature comes with DataNow"/>
            <Solution icon="mdi mdi-bank-transfer " title="Fast Withdrawal" text="Withrawals to bank is easy, get your funds without having to wait for payment platforms 24hrs settlement"/>         
            </Row>
            </Col>
            </Row>
            </div>
            <div  className="m-0 p-0"  id="pricing">
            <h2 className="p-3 text-center text-primary m-0"   >  Pricing</h2> 
            <h6  className="p-0 text-center text-dark m-0"  >We offer the best price for Automation in the market.</h6>

            <Row className="justify-content-center ">
            <div   className=" border rounded m-3 p-5   align-self-center bg-white shadow">
            <h5 className="text-center text-primary mt-0">  Registration Fee: ₦{formatCurrency(price)}</h5>
            <p className="text-center text-primary mb-0">  Data subscription(₦5 - ₦10 per transaction) </p>
            <p className="text-center text-primary mt-0">  Airtime subscription(0.5%, capped at ₦50 per transaction) </p>
            <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Data Automation, all networks</h5>
            <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Airtime Automation, all networks</h5>
            {/* <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Convert to Cash</h5> */}
            {/* <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Withdrawal to Bank</h5> */}
            <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Cable TV Payment</h5>
            <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Electricity Bills Payment</h5>
            <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Customer Website</h5>
            <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Customer Android Application</h5>
            <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Customer iOS Application</h5>
            <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Auto Wallet Funding</h5>
            <h5 className="mt-2 mb-3"><i className="mdi mdi-shield-check text-success"></i> Staff Dashboard</h5>
              </div>
            </Row>
            </div>

            <div  className="m-0 p-0 bg-white text-dark mt-5 pb-4"  id="faq">
            <h2 className="p-3 text-center   m-0"   >FAQs</h2> 
            
            <Row className="justify-content-center ">
                <Col md="8" className="align-self-center ">
            <Row >
            <Faq question="Do I need a developer to get started?" answer="No, you don't. If you already have hosting,  
             your website can be deployed in 5 minutes. You can also download your Android, iOS App and upload to their respective app Store (playStore and Apple Store)"/>
             <Faq question="I can't do all these myself, what do I do?" answer="You don't have to worry about that, our customer service can help you set up your 
              software in 30 minutes."/>
                <Faq question={"Is the ₦" + formatCurrency(price) + " registration fee annually?"} 
                answer="No, it is one time. However, we will charge you for usage using 'PAY AS YOU GO' method. Each data subscription done on your system attracts ₦5 - ₦10 airtime charge
                and Airtime attracts a transaction fee of 1% capped at ₦50 per transaction." />
             </Row>
             </Col></Row>
            </div>


             
            <Row className="justify-content-center bg-primary text-white pt-3" id="contact">
                <Col md="1"></Col>
                <Col md="7" className="align-self-center m-3">
                <h2 className="p-3 text-center   m-0"   >  Contact Us</h2> 

                <h6 className="mb-0 "   >We are just a message away!</h6>
                {/* <ResponseModal/> */}
                <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={submitContact}
              >
                  <Row>
                  <Col md="6">
                  <AvField
                  name="contactName"
                  label="Name"
                  value={tempData.contactName}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Type Name"
                  type="text"
                  required
                />
                  
                  </Col>
                  
                  <Col md="6">
                  <AvField
                    name="contactPhone"
                    label="Phone Number"
                    value={tempData.contactPhone}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Type Phone Number"
                    type="text"
                    validate={{
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Enter valid phone number",
                      },
                    }}
                />
                  
                  </Col>
                  
                  </Row>
               
 
                <AvField
                  name="contactEmail"
                  label="Email"
                  value={tempData.contactEmail}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Type Email address"
                  type="email"
                />

                <textarea className="form-control" placeholder="Type message"
                   onChange={(e) => {
                    formOnChange(e);
                  }}
                  name="contactMessage"
                  required
                  
                >
                    {tempData.contactMessage}
                 </textarea>
                   

                <Button
                  className="btn text-white  waves-effect waves-light mt-3 "
                  type="submit"
                  style={{backgroundColor:' #f57f17'}}
                >
                  Send Message
                </Button>
              </AvForm>
              </Col>
             <Col className="m-3 align-self-center">
             <h6><i className="mdi mdi-map-marker-check "></i>  No. 21 University Road, Tanke, Ilorin, Kwara State</h6>
             <h6><i className="mdi mdi-phone-in-talk "></i> Call Us : 08168670476</h6>
             <h6><i className="mdi mdi-email"></i> Email Us : support@systemstech.com.ng</h6>
             </Col>
             <Col xs="12">
             <p style={{fontSize:12}} className="text-center text-white">
                 <Link  className="text-white ml-2 mr-2" to='terms'>Terms and Conditions</Link>
             <Link className="text-white ml-2 mr-2" to='privacy'>Privacy Policy</Link>
             </p>
              </Col> 
              <Col xs="12">
                 <p style={{fontSize:16}} className="text-center pb-4">©{new Date().getFullYear()} Systems Technologies,  All rights reserved.</p>
             </Col>
             </Row>
 

            </React.Fragment>
            );
        };
        


        const Faq=(props)=>{
            const [open,setOpen]=useState(false)

            return (
                <Col xs="12">
                <Card className="mb-4 border rounded">
                <div  className="faq collapsed shadow border ">
                   <CardHeader className="text-dark" id="headingOne"   onClick={() => setOpen(!open)} style={{cursor:'pointer'}}>
                        <h6 className="m-0 faq-question">
                            <i className={open? "mdi mdi-arrow-up-drop-circle " : "mdi mdi-arrow-down-drop-circle "} 
                          ></i> {props.question}</h6>
                    </CardHeader>
                    </div>
                    <Collapse isOpen={open}>
                    <CardBody>
            <p className=" mb-0 faq-ans"> {props.answer}</p>
                    </CardBody>
                    </Collapse>
            </Card>
            </Col>
         
            )
        }

        
        const Solution=(props)=>{
            return (
                <Col md="6" >
                <Row className="m-4">
                         <div  className=" border border-white  mr-3 text-center align-content-center align-self-center">
                            <i style={{fontSize:70}} className={props.icon+ " m-3"}></i>
                        </div>
                     <Col md="7" className="m-0 p-0">
            <h5 className="text-uppercase " >{props.title}</h5>
                        <h6 >{props.text}</h6>
                    </Col>
                </Row>
            </Col>

            )
        }

        export default withRouter(SignIn);
        