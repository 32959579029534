import React, { useContext, useEffect, useState } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { SketchPicker, CirclePicker, SliderPicker, ChromePicker } from 'react-color';
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
  BusinessDataContext,
  TempDataContext,FormDataContext
} from "../../contexts/Contexts";
import ResponseModal from "../../components/ResponseModal";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
   const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
   const { formData, formDataDispatch } = useContext(FormDataContext);


  document.title = "Business Profile-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'modalOpen', value: false },
  });
    fetchTransactions();
  };

  const templates=[
    "Classic","Smart",
  ]

  useEffect(() => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "name", value: businessData.business.name },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "website", value: businessData.business.website },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "bank", value: businessData.business.bank },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "account_number", value: businessData.business.account_number },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "account_name", value: businessData.business.account_name },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "bvn", value: businessData.business.bvn },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "address", value: businessData.business.address },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "cac", value: businessData.business.cac },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "description", value: businessData.business.description },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: "template", value: businessData.business.template },});
    businessData.business.primary_color && formDataDispatch({type: "SET_FORM_DATA", data: { name: "primary_color", value: businessData.business.primary_color },});
    businessData.business.secondary_color && formDataDispatch({type: "SET_FORM_DATA", data: { name: "secondary_color", value: businessData.business.secondary_color },});
  
  }, []);

  const Field=(props)=>{
    return (
      <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
      <Col xs="5">
                      <b>{props.field}</b>
                    </Col>
                    <Col xs="7" className={props.className}>
                      <p className="m-0 p-0">{props.value}</p>
                    </Col>
                  </Row>
    )
  }

  const fetchTransactions = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", String(formData.name));
    urlencoded.append("website", String(formData.website));
    urlencoded.append("bank", String(formData.bank));
   urlencoded.append("account_number", String(formData.account_number));
    urlencoded.append("account_name", String(formData.account_name));
    urlencoded.append("bvn", String(formData.bvn));
    urlencoded.append("address", String(formData.address));
    urlencoded.append("cac", String(formData.cac));
    urlencoded.append("description", String(formData.description));
    urlencoded.append("template", String(formData.template));
    urlencoded.append("primary_color", String(formData.primary_color));
    urlencoded.append("secondary_color", String(formData.secondary_color));
   
 

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
     
      body: urlencoded,
    };
    fetch(
      localStorage.getItem("apiURL") + "business_profile",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        businessDataDispatch({ type: 'STORE_APP_DATA', businessData:{...businessData,business:data.data}})

        }
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      

       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
            formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };


  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className="text-uppercase text-left mb-0 "
                  >
                    <b>Business Profile</b>
                  </p>
                  <hr className="border-primary" />
                </div>

                <div className="mb-2 text-dark">
                <Field field="BUSINESS NAME" value = {businessData.business.name}/>
                <Field field="BUSINESS ID" value = {businessData.business.business_id}/>
                <Field field="WEBSITE" value = {businessData.business.website}/>
                <Field field="BANK STATUS" value = {businessData.business.bank_verified*1===0? 'Verified' : 'Not Verified'} className = {businessData.business.bank_verified*1===0? 'text-success' : 'text-danger'}/>
                <Field field="BANK" value = {businessData.banks[businessData.banks.findIndex(bank=> bank.id*1===businessData.business.bank*1)].name}/>
                <Field field="ACCOUNT NUMBER" value = {businessData.business.account_number}/>
                <Field field="ACCOUNT NAME" value = {businessData.business.account_name}/>
                <Field field="BVN NUMBER" value = {businessData.business.bvn}/>
                <Field field="ADDRESS" value = {businessData.business.address}/>
                <Field field="CAC REG. NO." value = {businessData.business.cac}/>
                <Field field="REGISTRATION DATE" value = {businessData.business.created_at}/>
                <Field field="APPLICATION TEMPLATE" value = {templates[businessData.business.template]}/>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                 <Col xs="5">
                      <b>APPLICATION PRIMARY COLOUR</b>
                    </Col>
                    <Col xs="2" className="ml-3" style={{backgroundColor:businessData.business.primary_color}}>
                     </Col>
                  </Row>

                  <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                 <Col xs="5">
                      <b>APPLICATION SECONDARY COLOUR</b>
                    </Col>
                    <Col xs="2" className="ml-3" style={{backgroundColor:businessData.business.secondary_color}}>
                     </Col>
                  </Row>
      
                <Field field="DESCRIPTION" value = {businessData.business.description}/>
                 </div>

                <hr />
               <div className="text-center"> <p
                  onClick={() => {
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: 'modalOpen', value: true },
                  });                  }}
                  style={{ cursor: "pointer" }}
                  className="text-uppercase   mb-2 text-primary "
                >
                  <b>Edit Business</b>
                </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    
    {tempData.modalOpen && (
        <Modal isOpen={true}>
        <ModalBody>
          <div className="mb-2 text-dark">
            <button
              type="button"
              className="close"
              onClick={() => {
                tempDataDispatch({
                  type: "SET_FORM_DATA",
                  data: { name: 'modalOpen', value: false },
              });
                }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p
              className="text-uppercase text-left mb-0 text-primary "
               
            >
              <b>Edit Profile</b>
            </p>
            <hr className="border-primary" />
            <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >
                <AvField
                  name="name"
                  label="Business Name"
                  value={formData.name}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  required
                />

<AvField
                  name="website"
                  label="Business Website"
                  value={formData.website}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter website ('http://www.mydomain.com')"
                  type="text"
                  required
                />

                    <p
                  className="text-uppercase text-left mb-0 mt-2"
                >
                  <b>Bank</b>
                </p>
                <select className="form-control mb-2" value={formData.bank} name="bank" required onChange={formOnChange}>
                          <option value="">--Select Bank---</option>
                          {businessData.banks.map((bank, index) => { return (<option key={bank.id} value={bank.id}>{bank.name}</option>) })}

                       
                           </select>
<AvField
      label="Account Number"
      name="account_number"
      value={formData.account_number}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Account Number"
      type="text"
      errorMessage="Enter valid account number"
      validate={{
        pattern: {
          value: "^[0-9]+$",
          errorMessage: "Only valid data",
        },
      }}
      />
      
      <AvField
                  name="account_name"
                  label="Account Name"
                  value={formData.account_name}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter account name"
                  type="text"
                />
 
 <AvField
                  name="bvn"
                  label="BVN for Account provided"
                  value={formData.bvn}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter account BVN"
                  type="text"
                  validate={{
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Only valid data",
                    },
                  }}
                />
 
 <AvField
                  name="address"
                  label="Business Address"
                  value={formData.address}
                  required
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter business address"
                  type="text"
                />

                 
<AvField
                  name="cac"
                  label="CAC REG. NO."
                  value={formData.cac}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter CAC"
                  type="text"
                />

<p
                  className="text-uppercase text-left mb-0 mt-2 "
                >
                  <b>Application Template</b>
                </p>
<select className="form-control mb-2" value={formData.template} name="template" required onChange={formOnChange}>
                          <option value="">--Select Template---</option>
                          {templates.map((template, index) => { return (<option key={index} value={index}>{template}</option>) })}

                       
                           </select>


                           <p className="text-uppercase text-left mb-0 mt-2 ">
                  <b>Application Primary Colour</b>
                </p>
                <ChromePicker disableAlpha
                className ="mb-2"
        color={ formData.primary_color }
        onChangeComplete={  (color)=>{
          formDataDispatch({type: "SET_FORM_DATA", data: { name: "primary_color", value: color.hex },});

        } }
      />

      
<p className="text-uppercase text-left mb-0 mt-2 ">
                  <b>Application Secondary Colour</b>
                </p>
                <ChromePicker disableAlpha
                className ="mb-2"
        color={ formData.secondary_color }
        onChangeComplete={  (color)=>{
          formDataDispatch({type: "SET_FORM_DATA", data: { name: "secondary_color", value: color.hex },});

        } }
      />
                                
 <AvField
                  name="description"
                  label="Business Description"
                  value={formData.description}
                  required
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Business description"
                  type="text"
                />

 
                <Button
                 
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  Save Changes
                </Button>
              </AvForm>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )}
    </React.Fragment>
  );
};

export default withRouter(Payments);
