import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal,ModalBody} from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
 import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
   
  document.title = "HTML Snippet";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
   };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("html", String(formData.html));
    var requestOptions = {
      method:  "PATCH",
      headers: myHeaders,
      body: urlencoded,
    };
    fetch(
        localStorage.getItem("apiURL") + "html_snippet"   ,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
   
       
        }
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.dataPlansLoaded ) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'dataPlansLoaded', value: true },
      });
    }
  }, []);
     
  
            const fetchDataPlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving HTML Snippet...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
              };
              fetch(
                localStorage.getItem("apiURL") + "html_snippet"  ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {       
                    formDataDispatch({  type: "SET_FORM_DATA",
                    data: { name:'html', value:data.data.html},
                  });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
         
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
          
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Website HTML Snippet</b>
                </p>
                <hr className="border-primary" />
                </div>

                      <div className="account-card-content mt-2 ">
                      {formData.error && <Alert color="danger">{formData.error}</Alert>}
                     <Row className="m-0">
                       <Col className="m-0" xl='6' xs='12'> 
                       <h6>Code</h6>

                       <Card className="mini-stat text-dark shadow">
                      <CardBody>
                        <AvForm
                        className="form-horizontal mt-1 mb-2"
                        onValidSubmit={handleSubmit}
                      >
                      <textarea
                      onChange={formOnChange}
                      value={formData.html}
                      name='html'
                      style={{width:'100%',height:'200px',fontFamily:'monospace'}}
                      />
                    
                        <Button
                          className="btn-primary w-md mt-2 waves-effect waves-light "
                          type="submit"
                        >
                        Save Changes
                        </Button>
                      </AvForm>
                      </CardBody>
                     </Card>
                      </Col>
                      <Col className="m-0" xl='6' xs='12'>
                        
                      <h6>Preview</h6>
                      <Card className="mini-stat text-dark shadow">
                <CardBody>
                      <div dangerouslySetInnerHTML={{ __html: formData.html }} />
                     </CardBody>
                     </Card>
                      </Col>
                      </Row>
                    </div>

                  
                     
                    </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        