import React, { createContext, useReducer } from 'react';
import { tempDataReducer } from '../reducers/TempDataReducer';

export const TempDataContext = createContext();

const TempDataContextProvider = (props) => {
  const [tempData, tempDataDispatch] = useReducer(tempDataReducer, {})

  return (
    <TempDataContext.Provider value={{ tempData: tempData,  tempDataDispatch }}>
      {props.children}
    </TempDataContext.Provider>
  );
}
 
export default TempDataContextProvider;