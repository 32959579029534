import React, { useContext, useEffect} from 'react';
import { withRouter,Redirect } from 'react-router-dom';
 import { BusinessDataContext} from '../../contexts/Contexts';

const SignOut = (props) => {
     const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

    useEffect(()=>{
         businessDataDispatch({ type: 'SIGNOUT' })
        props.history.push('/');    
    })

    return (
        <Redirect to='/' />
    );
}


export default withRouter((SignOut));

