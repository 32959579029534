import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
   const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
   const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "MTN SME Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    });
 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", String(formData.smeId));
     
   
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
     
      body: urlencoded,
    };
    fetch(
      localStorage.getItem("apiURL") + "mtn_sme_settings",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "smePlan", value:data.data}
          });

        }
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          tempDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
          tempDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        tempDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.smePlansLoaded ) {
      fetchsmePlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'smePlansLoaded', value: true },
      });
    }
  }, []);
      

            const fetchsmePlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving data plans...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
               
              };
              fetch(
                localStorage.getItem("apiURL") + "data_settings" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    const objectValues = Object.values(data.data.sme_plans);
                    console.log(objectValues);
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "smePlans", value: objectValues },
                    });
                    const smePlan=objectValues.find(plan=>plan.id===data.data.sme_plan);
                     tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "smePlan", value:smePlan  },
                    });
                    formDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "smeId", value:smePlan.id  },
                    });
                    
                  }
                  else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                }
                 else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    tempDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    tempDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  tempDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>MTN SME Plan</b></p>
                <hr className="border-primary" />
                </div>
                
                   
                  {tempData.smePlan && (
                    <React.Fragment>
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      <tr>
                      <th data-priority="1">Status</th>
                      <th data-priority="1">Name</th>
                      <th data-priority="1">Price</th>
                      <th data-priority="1">Shares Limit</th>
                      <th data-priority="1">Data Bundle</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
       <td> 
      {tempData.smePlan.status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {tempData.smePlan.statu*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
       
             <td>{tempData.smePlan.name}</td>           
             <td>₦{formatCurrency(tempData.smePlan.amount)}</td>           
             <td>{tempData.smePlan.share_limit}</td>           
             <td>{formatCurrency(tempData.smePlan.data_bundle)}</td>           
               <td className="text-primary" style={{cursor:"pointer"}}
                onClick={()=>{  tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "isModalOpen", value:true },
      });}} >Change Plan</td>
            </tr>
                      
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    
                          
                          </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.smePlan && ( <Modal isOpen={tempData.isModalOpen}  >
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {    tempDataDispatch({type: "SET_FORM_DATA",data: { name: 'isModalOpen', value: false}}); }} >
            
            <span aria-hidden="true">&times;</span>
          </button>
                  <p className="text-uppercase text-left mb-0 " ><b>Change MTN SME Plan</b></p>
                 
                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {tempData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >

<p
                  className="  text-left mb-0 mt-2 "
                >
                  <b>Plan Status</b>
                </p>
                <select className="form-control mb-2" value={formData.smeId} name="smeId" onChange={formOnChange}>
                             {tempData.smePlans.map((smePlan, index) => { return (<option key={{index}} value={smePlan.id}>{smePlan.name} for ₦{formatCurrency(smePlan.amount)}</option>) })}
                          </select>
                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  Save Changes
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        