import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,FormDataContext,
  BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";
import ResponseModal from "../../components/ResponseModal";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Marketplace-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
    fetchTransactions();
  };
  
  const selectNetwork = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
    fetchTransactions(e.target.value,formData.type);
  };
  const selectType = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
    fetchTransactions(formData.network,e.target.value);
  };
  
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
   purchaseData();
      };

      const planTypes = [
        "SME Data",
        "Normal Data",
        "Corporate Data"
      ];
   
  const fetchTransactions = (network,type) => {
   if(!network || !type)  
    return;

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving stock...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var parameter = "";
    parameter += network ? '&network=' + network : '';
    parameter += type ? '&type=' + type : '';
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
     };

    fetch(
      localStorage.getItem("apiURL") + "airtime_stock?"+ parameter,
      requestOptions
      )
      .then((response) => (response = response.text()))
      .then((response) => {
        console.log(response);

        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          const objectValues = Object.values(data.data);
           console.log('object values' +objectValues);
           tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "stock", 
            // value: objectValues.filter(dataPlan => dataPlan.network===formData.network)[0]
            value: objectValues[0]
          }});
 
        }
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
       else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "(unable to connect to server) - "+error,
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      

    };
    


            const purchaseData = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Purchasing Airtime...", isLoading: true },
              });  var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
              var urlencoded = new URLSearchParams();
              urlencoded.append("amount", String(formData.amount));
              var requestOptions = {
                method: "POST",
                headers: myHeaders,
               
                body: urlencoded,
              };
              fetch(
                localStorage.getItem("apiURL") + "buy_airtime_stock/"+tempData.stock.id,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
                  });

                  tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: 'history', value: {history:props.history, url :"/"} },
                });


                  tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: 'clearData', value:true },
                });
                
                  }
                  else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                }
                 else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                  {/* <ResponseModal/> */}
                <Row className="justify-content-center align-content-center ">
                 <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>DataNow Marketplace</b></p>
                <hr className="border-primary" />
                </div>
                <Row>
                  <Col md="6" >
                  {formData.error && <Alert color="danger">{formData.error}</Alert>}

                <AvForm 
                className="form-horizontal"
                onValidSubmit={handleSubmit}
              >
                  <p
                  className="  text-left mb-0 "
                >
                  <b>Select Stock</b>
                </p>
                <Row>
                <Col xs="6">
                   <select className="form-control mb-2" value={formData.type} name="type" onChange={(e)=>{selectType(e);} }>
                   <option value="">Select-Stock</option>
                   <option value="0">Data Airtime</option>
                  <option value="1">VTU Airtime</option>
                  </select>
                  </Col>
                  <Col xs="6">
                   <select className="form-control mb-2" value={formData.network} name="network" onChange={(e)=>{selectNetwork(e);} }>
                   <option value="">Select-Network</option>
                   <option value="MTN">MTN</option>
                  <option value="GLO">GLO</option>
                  <option value="AIRTEL">AIRTEL</option>
                  <option value="ETISALAT">ETISALAT</option>
                  </select>
                  </Col>
                  </Row>
               {tempData.stock &&
               <React.Fragment>
               <hr/>
                  <p className="  text-left mb-0 ">
                Stock Status: {tempData.stock.status*1 === 0 && tempData.stock.amount_remaining*1 >0 ? <b className="text-success">Available</b>:
                <b className="text-danger">Out of Stock</b>}
                </p>
                <p className="  text-left mb-0 ">
                Stock Remaining : {formatCurrency(tempData.stock.amount_remaining)} units
                </p>
                <p className="  text-left mb-0 ">
                Price per unit : {tempData.stock.price}%
                </p>
                <br/>
                
                {tempData.stock.status*1 === 0 && tempData.stock.amount_remaining*1 >0 &&
                <React.Fragment>
                    <AvField
      label="Enter amount to buy"
      name="amount"
      value={formData.amount}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Amount"
      type="number"
      errorMessage="Enter valid amount"
      validate={{
        required: { value: true },
        pattern: {
          value: "^[0-9]+$",
          errorMessage: "Only valid amount",
        },
      }}
      />
     
     <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                   {formData.amount ? ('Buy for  ₦'+ formatCurrency((tempData.stock.price*formData.amount)/100)) : 'Buy'}
                </Button>
                </React.Fragment>
                }
                </React.Fragment>
                }
                  </AvForm>
                  </Col></Row>
                          </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                         </React.Fragment>
                         )
                        };
                        
                        export default withRouter(Payments);
                        