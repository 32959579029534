import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  
  document.title = "Data Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
   };

  const calculateSample=()=>{
    var serverPrice =formData.price;
  var basePrice =formData.base_percentage ? (formData.base_percentage*serverPrice)/100 : serverPrice;
  var atmPrice=0;
  var walletPrice=0;

  if(formData.wallet_extra_percentage){
    walletPrice+= (serverPrice*formData.wallet_extra_percentage)/100;
  }
  

  if(formData.atm_extra_percentage){
    atmPrice+= (serverPrice*formData.atm_extra_percentage)/100;
  }
   

 atmPrice =(atmPrice+ basePrice);
 walletPrice=(walletPrice +basePrice);

  formDataDispatch({
    type: "SET_FORM_DATA",
    data: { name:'sample_atm', value: atmPrice },
  });
  formDataDispatch({
    type: "SET_FORM_DATA",
    data: { name: 'sample_wallet', value: walletPrice },
  });

  formDataDispatch({
    type: "SET_FORM_DATA",
    data: { name:'atm_price', value: atmPrice },
  });
  formDataDispatch({
    type: "SET_FORM_DATA",
    data: { name: 'wallet_price', value: walletPrice },
  });


  return {atmPrice:atmPrice, walletPrice: walletPrice};
  }
  
  const planSelect = (dataPlan) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'plan_id', value: dataPlan.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'status', value: dataPlan.status },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'name', value: dataPlan.name },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'atm_price', value: dataPlan.atm_price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'price', value: dataPlan.transaction_charge },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'wallet_price', value: dataPlan.wallet_price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'message', value: dataPlan.message },});
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    }); 
  
    // calculateSample();
  }
    
    
    
    const handleSubmit = (e) => {
      e.persist();
      e.preventDefault();
      loaderDispatch({
        type: "SET_LOADER",
        data: { text: "Saving Changes...", isLoading: true },
      });
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "isModalOpen", value:false },
      }); 
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", "Bearer " + businessData.token);
      var urlencoded = new URLSearchParams();
      urlencoded.append("plan_id", String(formData.plan_id));
      urlencoded.append("name", String(formData.name));
      urlencoded.append("status", String(formData.status));
      urlencoded.append("atm_price", String(formData.atm_price));
      urlencoded.append("wallet_price", String(formData.wallet_price));
      urlencoded.append("message", String(formData.message));
      
      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
       
        body: urlencoded,
      };
      fetch(
        localStorage.getItem("apiURL") + "data_settings",
        
        requestOptions
        )
        .then((response) => (response = response.text()))
        .then((response) => {
          const data = JSON.parse(response);
          loaderDispatch({
            type: "SET_LOADER",
            data: { text: "", isLoading: false },
          });
          console.log(data);
          if (data.status === "success") {
            tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
            });
            tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "dataPlans", value:tempData.dataPlans.map((dataPlan) => {
                if(data.data.id*1===dataPlan.id*1) 
                return {...dataPlan,'message':data.data.m,
                'atm_price':data.data.a
                ,'wallet_price':data.data.w
                ,'name':data.data.n
                ,'status':data.data.s, ...data.data}
                return dataPlan;
              } 
              )}
            });
            
          }
          else if (data.message === "Token Expired"){
            props.history.push('/signout');    
        }
        
          else  if (data.errors) {
            let errorString = "";
            const objectValues = Object.values(data.errors);
            objectValues.map((error) => {
              errorString = errorString + error + ", ";
            });
            formDataDispatch({
              type: "SET_ERROR",
              data: errorString,
            });
             tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "isModalOpen", value:false },
            }); 
          } else {
            formDataDispatch({
              type: "SET_ERROR",
              data: data.message,
            });
            tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: 'isModalOpen', value: true },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          formDataDispatch({
            type: "SET_ERROR",
            data: "unable to connect to server",
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'isModalOpen', value: true },
          });
          loaderDispatch({
            type: "SET_LOADER",
            data: { text: "", isLoading: false },
          });
        });
      };
      
      
      
      useEffect(() => {
        formDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: 'checked', value: [] },
        });
    
        if(!tempData.dataPlansLoaded ) {
          fetchDataPlans();
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'dataPlansLoaded', value: true },
          });
        }
      }, []);
      
      const filterNetwork =(e)=>{
        var dataPlan=tempData.dataPlansMaster;
        if(e.target.value)
        dataPlan=dataPlan.filter(dataPlan => dataPlan.network=== e.target.value)
        if(formData.filterStatus)
        dataPlan=dataPlan.filter(dataPlan => dataPlan.status*1 === formData.filterStatus*1)
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "dataPlans", 
          value: dataPlan
        }});
      }
      
      
      const filterStatus =(e)=>{
        var dataPlan=tempData.dataPlansMaster;
        if(e.target.value)
        dataPlan=dataPlan.filter(dataPlan => dataPlan.status*1 === e.target.value*1)
        if(formData.filterNetwork)
        dataPlan=dataPlan.filter(dataPlan => dataPlan.network=== formData.filterNetwork)
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "dataPlans", 
          value: dataPlan
        }});
      }
      
      
      const DataPlan = (props) => {
        
        return (
          <React.Fragment >
          <tr>
          
          <td   > 
          {props.dataPlan.status*1 === 0 && (<label className="badge   badge-success">
          Active</label>) }
          {props.dataPlan.status*1 === 1 && (<label className="badge  badge-danger">
          Disabled</label>) }
          </td>
          <td   > 
          {businessData.merchant_settings['data_master_'+props.dataPlan.network]*1 === 0 && (<label className="badge   badge-success">
          Active</label>) }
          {businessData.merchant_settings['data_master_'+props.dataPlan.network]*1 === 1 && (<label className="badge  badge-danger">
          Disabled</label>) }
          </td>
          <td   > 
          {props.dataPlan.master_status*1 === 0 && (<label className="badge   badge-success">
          Active</label>) }
          {props.dataPlan.master_status*1 === 1 && (<label className="badge  badge-danger">
          Disabled</label>) }
          </td>
          <td   > 
          {businessData.admin_settings[props.dataPlan.network+"_data_master"]*1 === 0 && (<label className="badge   badge-success">
          Active</label>) }
          {businessData.admin_settings[props.dataPlan.network+"_data_master"]*1 === 1 && (<label className="badge  badge-danger">
          Disabled</label>) }
          </td>
          <td   >{props.dataPlan.network}</td>
          <td   >{props.dataPlan.type*1===0 ? 'SME Data' : (props.dataPlan.type*1===1 ? 'Normal Data':'Corporate Data')}</td>
          <td   >{props.dataPlan.name}</td>
          <td  >{props.dataPlan.master_name}</td>
          <td  >₦{formatCurrency(props.dataPlan.atm_price)}</td>
          <td  >₦{formatCurrency(props.dataPlan.wallet_price)}</td>
          <td  >{props.dataPlan.type*1!==0 && '₦' }{formatCurrency(props.dataPlan.transaction_charge)}{props.dataPlan.type*1===0 && 'MB'}</td>
          <td  >₦{formatCurrency(businessData.admin_settings.data_subscription_charge)}</td>
          <td   >{props.dataPlan.message}</td>
          <td   >{props.dataPlan.master_message}</td>
          <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.dataPlan)}} >Edit</td>
          </tr>
          </React.Fragment>
          );
        };
        
        
        const fetchDataPlans = () => {
          loaderDispatch({
            type: "SET_LOADER",
            data: { text: "Retrieving data plans...", isLoading: true },
          });
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Authorization", "Bearer " + businessData.token);
          var requestOptions = {
            method: "GET",
            headers: myHeaders,
           
          };
          fetch(
            localStorage.getItem("apiURL") + "data_settings" ,
            requestOptions
            )
            .then((response) => (response = response.text()))
            .then((response) => {
              const data = JSON.parse(response);
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "", isLoading: false },
              });
              console.log(data);
              if (data.status === "success") {
                const objectValues = Object.values(data.data.data_plans);
                console.log(objectValues);
                tempDataDispatch({
                  type: "SET_FORM_DATA",
                  data: { name: "dataPlans", value: objectValues },
                });
                tempDataDispatch({
                  type: "SET_FORM_DATA",
                  data: { name: "dataPlansMaster", value: objectValues },
                });
                
              }
              
               else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                }
                else if (data.errors) {
                let errorString = "";
                const objectValues = Object.values(data.errors);
                objectValues.map((error) => {
                  errorString = errorString + error + ", ";
                });
                formDataDispatch({
                  type: "SET_ERROR",
                  data: errorString,
                });
              } else {
                formDataDispatch({
                  type: "SET_ERROR",
                  data: data.message,
                });
              }
            })
            .catch((error) => {
              console.log("error", error);
              formDataDispatch({
                type: "SET_ERROR",
                data: "unable to connect to server",
              });
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "", isLoading: false },
              });
            });
          };
          
          
          return (
            <React.Fragment>
            <Container fluid  >
            <Row className="justify-content-center align-content-center ">
            <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
            <CardBody>
            <div className="mb-2">
            <p className="text-uppercase text-left mb-0 "><b>Data Plans</b>
            </p>
            <hr className="border-primary" />
            </div>
            
            <Row>
            <Col md="3" xs="6" className="form-group mb-2">
            <select className="form-control" value={formData.filterNetwork} name="filterNetwork" onChange={(e)=>{formOnChange(e); filterNetwork(e)} }>
            <option value="">All Networks</option>
            <option value="MTN">MTN</option>
            <option value="GLO">GLO</option>
            <option value="AIRTEL">AIRTEL</option>
            <option value="ETISALAT">ETISALAT</option>
            </select>
            </Col>
            
            <Col md="2" xs="6" className="form-group mb-2">
            <select className="form-control" value={formData.filterStatus} name="filterStatus"  onChange={(e)=>{formOnChange(e); filterStatus(e)} }>
            <option value="">All Statuses</option>
            <option value={0}>Active</option>
            <option value={1}>Disabled</option>
            </select>
            </Col>
            </Row>
            
            {tempData.dataPlans && (
              <React.Fragment>
              <p className="text-uppercase text-left mb-2 mt-3 small">Showing  {tempData.dataPlans.length} Data Plans</p>
              
              <div className="table-rep-plugin">
              <Table responsive striped hover>
              <thead>
              
              <tr>
              <th data-priority="1">Status</th>
              <th data-priority="1">App Setting</th>
              <th data-priority="1">Server Plan Status</th>
              <th data-priority="1">Server Network Status</th>
              <th data-priority="1">Network</th>
              <th data-priority="1">Type</th>
              <th data-priority="1">Name</th>
              <th data-priority="1">Default Name</th>
              <th data-priority="1">ATM Price</th>
              <th data-priority="1">Wallet Price</th>
              <th data-priority="1">Plan Value</th>
              <th data-priority="1">Transaction Charge</th>
              <th data-priority="1">Message</th>
              <th data-priority="1">Default Message</th>
              
              </tr>
              </thead>
              <tbody>
              { tempData.dataPlans.map((dataPlan) => {
                return <DataPlan key={dataPlan.id} dataPlan={dataPlan} />;
              }) }
              </tbody>
              </Table>
              </div>
              
              </React.Fragment>
              )
            }
            
            </CardBody>
            </Card>
            </Col>
            </Row>
            </Container>
            {tempData.dataPlans && ( <Modal isOpen={tempData.isModalOpen}  >
              <ModalBody >
                <Row>
                  
              <Col md="12"  className="mb-2 mt-3 text-dark">
              <button type="button" className="close"
              onClick={() => {  tempDataDispatch({
                type: "SET_FORM_DATA",
                data: { name: "isModalOpen", value:false },
              });}} >
              <span aria-hidden="true">&times;</span>
              </button>
              <p className="text-uppercase text-left mb-0 " ><b>Edit Data Plan</b></p>
              
              <hr className="border-primary" />
              <p className="m-0 p-0"><b>Plan Value:₦{ formatCurrency(formData.price)}</b> </p>

              <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
              className="form-horizontal m-t-30"
              onValidSubmit={handleSubmit}
              >
              
              <p
              className="  text-left mb-0 mt-2 "
              >
              <b>Plan Status</b>
              </p>
              <select required className="form-control mb-2" value={formData.status} name="status" onChange={formOnChange}>
              <option value="0">Active</option>
              <option value="1">Disabled</option>
              </select>
              
              <AvField
              name="name"
              label="name"
              value={formData.name}
              onChange={(e) => {
                formOnChange(e);
              }}
              placeholder="Enter plan name"
              type="text"
              />
              
              
              <AvField
              label="Wallet Price"
              name="wallet_price"
              value={formData.wallet_price}
              onChange={(e) => { formOnChange(e) }}
              placeholder="Wallet Price"
              type="number"
              errorMessage="Enter valid amount"
              validate={{
                required: { value: true },
                pattern: {
                  value: "^[0-9]+$",
                  errorMessage: "Only valid amount",
                },
              }}
              />
              
              <AvField
              label="ATM Price"
              name="atm_price"
              value={formData.atm_price}
              onChange={(e) => { formOnChange(e) }}
              placeholder="ATM Price"
              type="number"
              errorMessage="Enter valid amount"
              validate={{
                required: { value: true },
                pattern: {
                  value: "^[0-9]+$",
                  errorMessage: "Only valid amount",
                },
              }}
              />
              
              
              
              <AvField
              name="message"
              label="Plan Message"
              value={formData.message}
              onChange={(e) => {
                formOnChange(e);
              }}
              placeholder="Enter Plan message"
              type="text"
              />
              
              
              
              
              <Button
              className="btn-primary w-md waves-effect waves-light "
              type="submit"
              >
              Save Changes
              </Button>
              </AvForm>
              </div>
              </Col>
              {/* <Col md="6" className="mb-2 mt-3  text-dark">
              <button type="button" className="close"
              onClick={() => {  tempDataDispatch({
                type: "SET_FORM_DATA",
                data: { name: "isModalOpen", value:false },
              });}} >
              <span aria-hidden="true">&times;</span>
              </button>
               <p className="text-uppercase text-left mb-0 " ><b>Price Calculator</b></p>
              
               <AvForm>
    


              <AvField
              label="Base Percentage"
              name="base_percentage"
              value={formData.base_percentage}
              onChange={(e) => { formOnChange(e) }}
              placeholder="Base Percentage"
              type="text"
              errorMessage="Enter valid percent"
              />
             



<AvField
              label="Wallet Extra  Percentage "
              name="wallet_extra_percentage"
              value={formData.wallet_extra_percentage}
              onChange={(e) => { formOnChange(e) }}
              placeholder="Wallet Extra Percentage"
              type="text"
              errorMessage="Enter valid amount"
              />
         
              <AvField
              label="ATM Extra  Percentage "
              name="atm_extra_percentage"
              value={formData.atm_extra_percentage}
              onChange={(e) => { formOnChange(e) }}
              placeholder="ATM Extra Percentage "
              type="text"
              errorMessage="Enter valid amount"
              />
              
              
                <p className="m-2 p-0"><b>Plan Value:₦{ formatCurrency(formData.price)}</b> </p>
                <p className="m-2 p-0"><b>Wallet Price:₦{ formatCurrency(formData.sample_wallet)}</b> </p>
                <p className="m-2 p-0"><b>ATM Price:₦{ formatCurrency(formData.sample_atm)}</b> </p>

              <Button
              className="btn-danger w-md waves-effect waves-light "
              onClick={()=>{calculateSample()}}
  >
              Set Prices
              </Button>
              </AvForm>
               </Col> */}

              </Row>
              </ModalBody>
              </Modal>)
            }
            </React.Fragment>
            );
          };
          
          export default withRouter(Payments);
          