import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

  document.title = "Electric Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (electricPlan) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'plan_id', value: electricPlan.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'status', value: electricPlan.status },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'name', value: electricPlan.name },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'atm_price', value: electricPlan.atm_price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'wallet_price', value: electricPlan.wallet_price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'message', value: electricPlan.message },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'commission', value: electricPlan.commission },});
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });  }

 
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("plan_id", String(formData.plan_id));
    urlencoded.append("name", String(formData.name));
    urlencoded.append("status", String(formData.status));
    urlencoded.append("atm_price", String(formData.atm_price));
    urlencoded.append("wallet_price", String(formData.wallet_price));
    urlencoded.append("message", String(formData.message));
    urlencoded.append("commission", String(formData.commission));
   
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
     
      body: urlencoded,
    };
    fetch(
      localStorage.getItem("apiURL") + "electric_settings",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "electricPlans", value:tempData.electricPlans.map((electricPlan) => {
            if(data.data.id*1===electricPlan.id*1) 
            return {...electricPlan,'message':data.data.m,
            'atm_price':data.data.a
            ,'wallet_price':data.data.w
            ,'name':data.data.n
            ,'commission':data.data.c
            ,'status':data.data.s, ...data.data}
            return electricPlan;
           } 
         )}
          });

        }
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'isModalOpen', value: true },
        });
        
              } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'isModalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.electricPlansLoaded ) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'electricPlansLoaded', value: true },
      });
    }
  }, []);
    
  const filterDisco =(e)=>{
    var electricPlan=tempData.electricPlansMaster;
   if(e.target.value)
    electricPlan=electricPlan.filter(electricPlan => electricPlan.disco=== e.target.value)
    if(formData.filterStatus)
    electricPlan=electricPlan.filter(electricPlan => electricPlan.status*1 === formData.filterStatus*1)
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "electricPlans", 
      value: electricPlan
    }});
  }

  
  const filterStatus =(e)=>{
    var electricPlan=tempData.electricPlansMaster;
    if(e.target.value)
    electricPlan=electricPlan.filter(electricPlan => electricPlan.status*1 === e.target.value*1)
    if(formData.filterDisco)
    electricPlan=electricPlan.filter(electricPlan => electricPlan.disco=== formData.filterDisco)
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "electricPlans", 
      value: electricPlan
    }});
  }

    
  const ElectricPlan = (props) => {

    return (
      <React.Fragment >
       <tr>
       <td   > 
      {props.electricPlan.status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.electricPlan.status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   > 
      {businessData.merchant_settings['electric_master']*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {businessData.merchant_settings['electric_master']*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   > 
      {props.electricPlan.master_status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.electricPlan.master_status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   >{props.electricPlan.disco}</td>
             <td   >{props.electricPlan.name}</td>
              <td  >{props.electricPlan.master_name}</td>
              <td  >{formatCurrency(props.electricPlan.business_commission_fee)}%</td>
              <td  >{formatCurrency(props.electricPlan.commission)}%</td>
             <td   >{props.electricPlan.message}</td>
             <td   >{props.electricPlan.master_message}</td>
              <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.electricPlan)}} >Edit</td>
            </tr>
              </React.Fragment>
              );
            };
        

            const fetchDataPlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving electric plans...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
               
              };
              fetch(
                localStorage.getItem("apiURL") + "electric_settings" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    const objectValues = Object.values(data.data.electric_plans);
                    console.log(objectValues);
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "electricPlans", value: objectValues },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "electricPlansMaster", value: objectValues },
                    });
                  
                  } else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                }
                 else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Electric Plans</b></p>
                <hr className="border-primary" />
                </div>
               
                 <Row>
                  <Col md="3" xs="6" className="form-group mb-2">
                  <select className="form-control" value={formData.filterDisco} name="filterDisco" onChange={(e)=>{formOnChange(e); filterDisco(e)} }>
                  <option value="">All Discos</option>
                  <option value="IKEDC">IKEDC - Ikeja Electric</option>
                  <option value="EKEDC">EKEDC - Eko Electric</option>
                  <option value="KEDCO">KEDCO - Kano Electric</option>
                  <option value="PHED">PHED - Port Harcourt Electric</option>
                  <option value="JED">JED - Jos Electric</option>
                  <option value="IBEDC">IBEDC - Ibadan Electric</option>
                  <option value="KAEDCO">KAEDCO - Kaduna Electric</option>
                  <option value="AEDC">AEDC - Abuja Electric</option>
                  </select>
                  </Col>

                      <Col md="2" xs="6" className="form-group mb-2">
                          <select className="form-control" value={formData.filterStatus} name="filterStatus"  onChange={(e)=>{formOnChange(e); filterStatus(e)} }>
                          <option value="">All Statuses</option>
                          <option value={0}>Active</option>
                          <option value={1}>Disabled</option>
                         </select>
                        </Col>
                  </Row>
                   
                  {tempData.electricPlans && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing  {tempData.electricPlans.length} Data Plans</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                      <th data-priority="1">Status</th>
                      <th data-priority="1">App Electric Status</th>
                      <th data-priority="1">Server Electric Status</th>
                      <th data-priority="1">Disco</th>
                      <th data-priority="1">Name</th>
                      <th data-priority="1">Default Name</th>
                      <th data-priority="1">Total Commission</th>
                      <th data-priority="1">User Commission</th>
                      <th data-priority="1">Message</th>
                      <th data-priority="1">Default Message</th>
 
                      </tr>
                      </thead>
                      <tbody>
                      { tempData.electricPlans.map((electricPlan) => {
                        return <ElectricPlan key={electricPlan.id} electricPlan={electricPlan} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.electricPlans && ( <Modal isOpen={tempData.isModalOpen}  >
      {/* toggle={modalOpen} */}
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {  tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "isModalOpen", value:false },
            });}} >
            <span aria-hidden="true">&times;</span>
          </button>
                  <p className="text-uppercase text-left mb-0 " ><b>Edit Electric Plan</b></p>
                 
                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >

<p
                  className="  text-left mb-0 mt-2 "
                >
                  <b>Plan Status</b>
                </p>
                <select required className="form-control mb-2" value={formData.status} name="status" onChange={formOnChange}>
                <option value="0">Active</option>
                  <option value="1">Disabled</option>
                  </select>

                 <AvField
                  name="name"
                  label="name"
                  value={formData.name}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter plan name"
                  type="text"
                 />
 
          
                                     
<AvField
      label="User Commission less than or equal to total Commission"
      name="commission"
      value={formData.commission}
      onChange={(e) => { formOnChange(e) }}
      placeholder="User Commission "
      type="text"
      errorMessage="Enter valid amount"
     required
      />

<AvField
                  name="message"
                  label="Plan Message"
                  value={formData.message}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Plan message"
                  type="text"
                 />
  
     
                

                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  Save Changes
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        