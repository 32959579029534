import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Modal,ModalBody } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
  TempDataContext, FormDataContext,
     BusinessDataContext

} from "../../contexts/Contexts";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  const { formData, formDataDispatch } = useContext(FormDataContext);
   const [modalOpen, setModal ] = useState(false)

  document.title = "Airtime Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (airtimePlan) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'network', value: airtimePlan.network },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'status', value: airtimePlan.status },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'name', value: airtimePlan.name },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'atm_price', value: airtimePlan.atm_price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'wallet_price', value: airtimePlan.wallet_price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'message', value: airtimePlan.message },});
    setModal(true)
  }

 
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("network", String(formData.network));
    urlencoded.append("status", String(formData.status));
    urlencoded.append("atm_price", String(formData.atm_price));
    urlencoded.append("wallet_price", String(formData.wallet_price));
    urlencoded.append("message", String(formData.message));
   
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
     
      body: urlencoded,
    };
    fetch(
      localStorage.getItem("apiURL") + "airtime_settings",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "airtimePlans", value:tempData.airtimePlans.map((airtimePlan) => {
            if(data.data.id===airtimePlan.id) return {...airtimePlan, ...data.data}
            return airtimePlan;
           } 
         )}
          });
       

        }
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
                formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.airtimePlansLoaded ) {
      fetchairtimePlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'airtimePlansLoaded', value: true },
      });
    }
  }, []);
     
    
  const AirtimePlan = (props) => {

    return (
      <React.Fragment >
       <tr>
       <td   > 
      {props.airtimePlan.status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.airtimePlan.status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   > 
      {props.airtimePlan.master_status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.airtimePlan.master_status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   >{props.airtimePlan.network}</td>           
             <td  >{props.airtimePlan.atm_price}%</td>
             <td  >{props.airtimePlan.wallet_price}%</td>
             <td  >{businessData.admin_settings.airtime_subscription_charge}%</td>
             <td   >{props.airtimePlan.message}</td>
             <td   >{props.airtimePlan.master_message}</td>
             <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.airtimePlan)}} >Edit</td>
            </tr>
              </React.Fragment>
              );
            };
        

            const fetchairtimePlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving data plans...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
               
              };
              fetch(
                localStorage.getItem("apiURL") + "airtime_settings" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    const objectValues = Object.values(data.data.airtime_plans);
                    console.log(objectValues);
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "airtimePlans", value: objectValues },
                    });
                    
                  } 
                   else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                }
                else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="10" ml="10" sm="12">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Airtime Plans</b></p>
                <hr className="border-primary" />
                </div>
                
                   
                  {tempData.airtimePlans && (
                    <React.Fragment>
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                      <th data-priority="1">Status</th>
                      <th data-priority="1">Server Status</th>
                      <th data-priority="1">Network</th>
                      <th data-priority="1">ATM Price</th>
                      <th data-priority="1">Wallet Price</th>
                      <th data-priority="1">Transaction Charge</th>
                      <th data-priority="1">Message</th>
                      <th data-priority="1">Default Message</th>

                      </tr>
                      </thead>
                      <tbody>
                      { tempData.airtimePlans.map((airtimePlan) => {
                        return <AirtimePlan key={airtimePlan.id} airtimePlan={airtimePlan} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    
                          
                          </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.airtimePlans && ( <Modal isOpen={modalOpen}  >
      {/* toggle={modalOpen} */}
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {setModal(false)}} >
            <span aria-hidden="true">&times;</span>
          </button>
                  <p className="text-uppercase text-left mb-0 " ><b>Edit Airtime Plan</b></p>
                 
                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >

<p
                  className="  text-left mb-0 mt-2 "
                >
                  <b>Plan Status</b>
                </p>
                <select required className="form-control mb-2" value={formData.status} name="status" onChange={formOnChange}>
                <option value="0">Active</option>
                  <option value="1">Disabled</option>
                  </select>

                  
 <AvField
      label="ATM Price (%) e.g 96%"
      name="atm_price"
      value={formData.atm_price}
      onChange={(e) => { formOnChange(e) }}
      placeholder="ATM Price"
      type="number"
      errorMessage="Enter valid amount"
      />


                  
<AvField
      label="Wallet Price (%) e.g 95.5%"
      name="wallet_price"
      value={formData.wallet_price}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Wallet Price"
      type="number"
      errorMessage="Enter valid amount"
      />

<AvField
                  name="message"
                  label="Plan Message"
                  value={formData.message}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Plan message"
                  type="text"
                 />
  
     
                

                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  Save Changes
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        